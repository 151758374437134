import React from 'react';
import cx from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Actions from '@/components/cards/ArticleCard/Actions';
import ContentstackImage from '@/components/ContentstackImage';
import { Lock } from '@/icons';
import { articleType } from '@/types';
import { articleImage } from '@/utilities/articles/articleCard';
import { useTranslation } from '@separate/hooks/translation';
import { formatLocalDateSimple } from '@separate/utilities/datetime';
import styles from './article.module.scss';

export function ArticleListItem(props) {
  const { article, userIsPremium, isLoading, isLoggedIn } = props;
  const { t } = useTranslation();

  const premiumUserAndArticle = userIsPremium && article.is_premium;
  const loadingUserAndArticleIsPremium = isLoading && article.is_premium;
  const isLocked = !userIsPremium && article.is_premium;
  const linkToArticle = isLocked ? '#/' : `/articles/${article.article_category}${article.url}`;

  const [showMessage, setShowMessage] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);

  const handleCardClick = () => {
    if (!isLocked) return;

    setShowMessage(true);
  };

  const handleLogin = () => {
    setShowMessage(false);
    setShowLogin(true);
  };

  return (
    <>
      <Link href={linkToArticle}>
        <a className={styles.articleListLink} onClick={handleCardClick}>
          <div className={`${styles.articleList} d-flex align-items-center justify-content-between article-list`}>
            <div className={styles.info}>
              <h4 className="mb-1">{article.title}</h4>
              <p>
                {`${t('articles.published')} ${formatLocalDateSimple(article?.publish_details.time)}`}
              </p>
              <div className={`${styles.button} text-capitalize`}>
                {t(`articles.categories.${article.article_category}`)}
              </div>
            </div>
            <div className={styles.imageWrapper}>
              <ContentstackImage
                src={articleImage(article)}
                alt={t('articles.article_image')}
                className={styles.articleImage}
                width={300}
              />
              {loadingUserAndArticleIsPremium || isLocked ? (
                <div className={cx(styles.lock, "d-flex flex-column justify-content-center align-items-center")}>
                  <Lock className="mb-1" />
                  SEP+
                </div>
              ) : premiumUserAndArticle && (
                <div className={cx(styles.premiumUserAndArticle, "d-flex flex-column justify-content-center align-items-center")}>
                  SEP+
                </div>
              )}
            </div>
          </div>
        </a>
      </Link>
      {isLocked && (
        <Actions
          showMessage={showMessage}
          showLogin={showLogin}
          dismissMessage={() => setShowMessage(false)}
          dismissLogin={() => setShowLogin(false)}
          handleLogin={handleLogin}
          isLoggedIn={isLoggedIn}
        />
      )}
    </>
  );
}

ArticleListItem.propTypes = {
  article: articleType,
  userIsPremium: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
};
